import { INBOX_TYPES } from 'shared/mixins/inboxMixin';

export const getInboxClassByType = (type, phoneNumber) => {
  // eslint-disable-next-line no-console
  console.log(type);
  switch (type) {
    case INBOX_TYPES.WEB:
      return 'globe-desktop';

    case INBOX_TYPES.FB:
      return 'brand-facebook';

    case INBOX_TYPES.TWITTER:
      return 'brand-twitter';

    case INBOX_TYPES.TWILIO:
      return phoneNumber?.startsWith('whatsapp')
        ? 'brand-whatsapp'
        : 'brand-sms';

    case INBOX_TYPES.WHATSAPP:
      return 'brand-whatsapp';

    case INBOX_TYPES.API:
      return 'cloud';

    case INBOX_TYPES.EMAIL:
      return 'mail';

    case INBOX_TYPES.TELEGRAM:
      return 'brand-telegram';

    case INBOX_TYPES.VIBER:
      return 'brand-viber';

    case INBOX_TYPES.LINE:
      return 'brand-line';

    default:
      return 'chat';
  }
};

export const getInboxWarningIconClass = (type, reauthorizationRequired) => {
  if (type === INBOX_TYPES.FB && reauthorizationRequired) {
    return 'warning';
  }
  return '';
};
